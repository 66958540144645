import {useMemo} from "react";

const dayRange = (month, startDay, endDay) => {
    const days = [];

    for (let day = startDay; day <= endDay; day++) {
        days.push({month, day});
    }

    return days;
};

const months = [
    {month: 2, name: "Februari 2024", leoWork: 80, hannaWork: 100, days: 29, weekDayStart: 4},
    {month: 3, name: "Mars 2024", leoWork: 40, hannaWork: 100, days: 31, weekDayStart: 5},
    {month: 4, name: "April 2024", leoWork: 40, hannaWork: 100, days: 30, weekDayStart: 1},
    {month: 5, name: "Maj 2024", leoWork: 40, hannaWork: 60, days: 31, weekDayStart: 3},
    {month: 6, name: "Juni 2024", leoWork: 40, hannaWork: 60, days: 30, weekDayStart: 6},
    {month: 7, name: "Juli 2024", leoWork: 40, hannaWork: 60, days: 31, weekDayStart: 1},
    {month: 8, name: "Augusti 2024", leoWork: 40, hannaWork: 60, days: 31, weekDayStart: 4},
    {month: 9, name: "September 2024", leoWork: 100, hannaWork: 100, days: 30, weekDayStart: 0},
    {month: 10, name: "Oktober 2024", leoWork: 100, hannaWork: 100, days: 31, weekDayStart: 2}
];

const weeks = [
    {month: 2, day: 5, week: 6},
    {month: 2, day: 12, week: 7},
    {month: 2, day: 19, week: 8},
    {month: 2, day: 26, week: 9},
    {month: 3, day: 4, week: 10},
    {month: 3, day: 11, week: 11},
    {month: 3, day: 18, week: 12},
    {month: 3, day: 25, week: 13},
    {month: 4, day: 1, week: 14},
    {month: 4, day: 8, week: 15},
    {month: 4, day: 15, week: 16},
    {month: 4, day: 22, week: 17},
    {month: 4, day: 29, week: 18},
    {month: 5, day: 6, week: 19},
    {month: 5, day: 13, week: 20},
    {month: 5, day: 20, week: 21},
    {month: 5, day: 27, week: 22},
    {month: 6, day: 3, week: 23},
    {month: 6, day: 10, week: 24},
    {month: 6, day: 17, week: 25},
    {month: 6, day: 23, week: 26},
    {month: 7, day: 1, week: 27},
    {month: 7, day: 8, week: 28},
    {month: 7, day: 15, week: 29},
    {month: 7, day: 22, week: 30},
    {month: 7, day: 29, week: 31},
    {month: 8, day: 5, week: 32},
    {month: 8, day: 12, week: 33},
    {month: 8, day: 19, week: 34},
    {month: 8, day: 26, week: 35},
    {month: 9, day: 2, week: 36},
    {month: 9, day: 9, week: 37},
    {month: 9, day: 16, week: 38},
    {month: 9, day: 23, week: 39},
    {month: 9, day: 30, week: 40},
    {month: 10, day: 7, week: 41},
    {month: 10, day: 14, week: 42},
    {month: 10, day: 21, week: 43},
    {month: 10, day: 28, week: 44}
];

const deliveries = [
    {month: 2, day: 26, what: "stomme", done: true},
    {month: 3, day: 5, what: "takstolar", done: true},
    {month: 3, day: 12, what: "ventilation, murstock", done: true},
    {month: 3, day: 13, what: "panel, fönster, snickeri", done: true},
    {month: 3, day: 19, what: "takpannor", done: true},
    {month: 3, day: 20, what: "isolering, pärlspont veranda", done: true},
    {month: 5, day: 3, what: "lösull", done: true},
    {month: 5, day: 21, what: "skivmaterial", done: true},
    {month: 5, day: 24, what: "provtrycka", done: true},
    {month: 6, day: 18, what: "fönsterbänkar", done: true},
    {month: 7, day: 3, what: "trapp", done: true},
    {month: 7, day: 9, what: "lister, golv", done: true},
    {month: 7, day: 15, what: "tätskikt", done: true},
    {month: 8, day: 20, what: "kakelugn"},
    {month: 8, day: 9, what: "Vedum?", done: true},
    {month: 8, day: 5, what: "värmepump och WC/D", done: true},
    {month: 8, day: 13, what: "Elextrolux Home", done: true},
    {month: 8, day: 29, what: "innedörrar", done: true},
    {month: 9, day: 9, what: "bänkskiva kök", done: true},
    {month: 9, day: 2, what: "vitvaror", done: true},
    {month: 10, day: 8, what: "ventilations justering + OVK"}
];

const deadline = [
    {month: 2, day: 21, what: "beställa murstock", done: true},
    {month: 3, day: 25, what: "KA + kommunen 14:00", done: true},
    {month: 4, day: 16, what: "boka lösull", done: true},
    {month: 4, day: 28, what: "besluta om övervåning", done: true},
    {month: 5, day: 15, what: "bestämma elutag osv", done: true},
    {month: 5, day: 20, what: "boka provtryckning", done: true},
    {month: 5, day: 26, what: "beställa golv och innedörrar", done: true},
    {month: 6, day: 10, what: "professionell inredare", done: true},
    {month: 6, day: 23, what: "färger / tapet", done: true},
    {month: 6, day: 3, what: "hämta pärlspont tak", done: true},
    {month: 7, day: 6, what: "beställa våttskikt, klinkers", done: true},
    {month: 9, day: 10, what: "bestämma kakel, klinkers WC"},
    {month: 8, day: 1, what: "väggar + tak kök, vardagsrum, entré", done: true},
    {month: 9, day: 16, what: "boka OVK", done: true},
    {month: 8, day: 28, what: "säga upp lgh", done: true},
    {month: 9, day: 1, what: "väggar + tak veranda", done: true}
];

const vacations = [
    {month: 2, day: 26},
    {month: 2, day: 27},
    {month: 2, day: 28},
    ...dayRange(9, 2, 6),
    ...dayRange(9, 9, 13),
    ...dayRange(9, 16, 20),
    ...dayRange(9, 23, 27),
    {month: 9, day: 30},
    ...dayRange(10, 1, 4),
    ...dayRange(10, 7, 11),
    ...dayRange(10, 14, 18),
    ...dayRange(10, 21, 25)
];

const workDays = [
    {month: 2, day: 1},
    {month: 2, day: 2},
    {month: 2, day: 5},
    {month: 2, day: 6},
    {month: 2, day: 8},
    {month: 2, day: 9},
    {month: 2, day: 12},
    {month: 2, day: 13},
    {month: 2, day: 15},
    {month: 2, day: 16},
    {month: 2, day: 19},
    {month: 2, day: 20},
    {month: 2, day: 22},
    {month: 2, day: 23},
    {month: 3, day: 6},
    {month: 3, day: 7},
    {month: 3, day: 11},
    {month: 3, day: 21},
    {month: 3, day: 22},
    {month: 3, day: 27},
    {month: 3, day: 28},
    {month: 4, day: 3},
    {month: 4, day: 4},
    {month: 4, day: 10},
    {month: 4, day: 11},
    {month: 4, day: 17},
    {month: 4, day: 18},
    {month: 4, day: 24},
    {month: 4, day: 25},
    {month: 5, day: 2},
    ...dayRange(5, 14, 15),
    ...dayRange(5, 22, 23),
    ...dayRange(5, 29, 30),
    {month: 6, day: 5},
    ...dayRange(6, 12, 13),
    ...dayRange(6, 19, 20),
    ...dayRange(6, 26, 27),
    ...dayRange(7, 3, 4),
    ...dayRange(7, 10, 11),
    ...dayRange(7, 17, 18),
    ...dayRange(7, 24, 25),
    {month: 7, day: 31},
    {month: 8, day: 1},
    ...dayRange(8, 7, 8),
    ...dayRange(8, 14, 15),
    ...dayRange(8, 20, 20),
    ...dayRange(8, 22, 22),
    ...dayRange(8, 28, 29)
];

const offDays = [
    {month: 3, day: 31, what: "Kalle 80", half: true},
    {month: 4, day: 5, what: "Danmark", half: true},
    {month: 4, day: 6, what: "Danmark"},
    {month: 4, day: 7, what: "Danmark"},
    {month: 4, day: 30, what: "Valborg", half: true},
    {month: 5, day: 31, what: "Kevin + Melene bröllop"},
    {month: 6, day: 1, what: "Kevin + Melene bröllop"},
    {month: 6, day: 2, what: "Kevin + Melene bröllop"},
    {month: 6, day: 21, what: "Midsommar"},
    {month: 6, day: 22, what: "Midsommar"},
    {month: 7, day: 18, what: "Östersjö", half: true},
    {month: 7, day: 19, what: "Östersjö"},
    {month: 7, day: 20, what: "Östersjö"},
    {month: 7, day: 21, what: "Östersjö"},
    {month: 8, day: 24, what: "Rymans"},
    {month: 8, day: 25, what: "Rymans"},
    {month: 8, day: 26, what: "5-år"},
    {month: 8, day: 27, what: "5-år"}
];

const hanna = [
    {month: 3, day: 22},
    ...dayRange(3, 25, 28),
    {month: 5, day: 3},
    {month: 5, day: 8},
    {month: 5, day: 10},
    {month: 5, day: 20},
    {month: 5, day: 21},
    {month: 5, day: 27},
    {month: 5, day: 31},
    {month: 6, day: 7},
    {month: 6, day: 10},
    {month: 6, day: 14},
    {month: 6, day: 17},
    {month: 6, day: 18},
    {month: 6, day: 24},
    {month: 6, day: 28},
    {month: 7, day: 1},
    {month: 7, day: 5},
    {month: 7, day: 9},
    {month: 7, day: 12},
    {month: 7, day: 19},
    {month: 7, day: 22},
    {month: 7, day: 23},
    {month: 7, day: 29},
    {month: 7, day: 30},
    {month: 8, day: 5},
    {month: 8, day: 6},
    {month: 8, day: 12},
    {month: 8, day: 13},
    {month: 8, day: 19},
    {month: 8, day: 23},
    {month: 8, day: 26},
    {month: 8, day: 27},
    {month: 9, day: 2},
    {month: 9, day: 3},
    ...dayRange(9, 16, 20),
    ...dayRange(9, 23, 27),
    {month: 9, day: 30},
    ...dayRange(10, 1, 4),
    ...dayRange(10, 7, 11),
    ...dayRange(10, 14, 18),
    ...dayRange(10, 21, 25)
];

const holidays = [
    {month: 3, day: 29, what: "Påsk", done: true},
    {month: 3, day: 30, what: "Påsk", done: true},
    {month: 3, day: 31, what: "Påsk", done: true},
    {month: 4, day: 1, what: "Påsk", done: true},
    {month: 5, day: 1, what: "Första maj"},
    {month: 5, day: 9, what: "Kristi himmelsfärd"},
    {month: 6, day: 6, what: "Nationaldagen"}
];

const buffers = [
    ...dayRange(5, 7, 8),
    ...dayRange(5, 11, 13),
    ...dayRange(5, 18, 19),
    ...dayRange(5, 21, 21),
    ...dayRange(5, 24, 26),
    ...dayRange(8, 5, 5),
    ...dayRange(9, 12, 22)
];

const reservedDays = [
    ...dayRange(2, 3, 4),
    {month: 2, day: 7},
    ...dayRange(2, 10, 11),
    {month: 2, day: 14},
    ...dayRange(2, 17, 18),
    {month: 2, day: 21},
    ...dayRange(2, 26, 29),
    {month: 3, day: 1},
    ...dayRange(3, 4, 5),
    ...dayRange(3, 8, 10),
    ...dayRange(3, 12, 15),
    ...dayRange(3, 18, 20),
    ...dayRange(3, 26, 26),
    {month: 4, day: 2},
    {month: 4, day: 5},
    ...dayRange(4, 8, 9),
    ...dayRange(4, 15, 16),
    ...dayRange(4, 29, 30),
    {month: 5, day: 20},
    ...dayRange(6, 8, 11),
    ...dayRange(6, 14, 17)
];

const moments = [
    {days: 2, what: "Plintar till veranda", done: true},
    {days: 2, what: "Klimatskivor, spikreglar", done: true},
    {days: 2, what: "Bygga murstock", done: true},
    {days: 3, what: "Ventilation", done: true},
    {days: 1, what: "Måla snickeri", done: true},
    {days: 1, what: "Måla snickeri", done: true},
    {days: 2, what: "Måla pärlspont veranda"},
    {days: 2, what: "Issolera undervåning", done: true},
    {days: 1, what: "Issolera fönster + dörrar undervåning", done: true},
    {days: 1, what: "Plasta undervåning", done: true},
    {days: 1, what: "Isolera ytterväggar övervåning", done: true},
    {days: 1, what: "Isolera fönster + plasta ytterväggar övervåning", done: true},
    {days: 1, what: "Plasta tak övervåning", done: true},
    {days: 1, what: "Glesa övervåning", done: true},
    {days: 2, what: "Regla installationsskikt", done: true},
    {days: 2, what: "Regla innerväggar", done: true},
    {days: 4, what: "Regla innerväggar", done: true},
    {days: 2, what: "VVS fas1", done: true},
    {days: 2, what: "Elektriker fas 1", done: true},
    {days: 3, what: "Issolera installationsskikt, Plyfa", done: true},
    {days: 2, what: "Plyfa", done: true},
    {days: 1, what: "Plyfa", done: true},
    {days: 3, what: "Gips hallen", done: true},
    {days: 1, what: "Pärlspont vardagsrum", done: true},
    {days: 1, what: "Pärlspont vardagsrum", done: true},
    {days: 1, what: "Pärlspont bibliotek, tapet hall", done: true},
    {days: 2, what: "Pärlspont kök", done: true},
    {days: 1, what: "Pärlspont groventré", done: true},
    {days: 1, what: "Gips kök", done: true},
    {days: 1, what: "Spackel kök", done: true},
    {days: 1, what: "Spackel kök", done: true},
    {days: 1, what: "Gips vardagsrum + spackel", done: true},
    {days: 1, what: "Gips vardagsrum + spackel", done: true},
    {days: 1, what: "Pärlspont hall", done: true},
    {days: 1, what: "Pärlspont hall", done: true},
    {days: 1, what: "Gips bibliotek + grovéntre", done: true},
    {days: 2, what: "Spackel  bibliotek + grovéntre", done: true},
    {days: 2, what: "Golv kök", done: true},
    {days: 1, what: "Våtskikt groventré", done: true},
    {days: 2, what: "Fönsterbrädor, smygar, foder, taklist kök", done: true},
    {days: 2, what: "Skorsten"},
    {days: 2, what: "Klinker groventré", done: true},
    {days: 1, what: "Tapet bibliotek, hall", done: true},
    {days: 1, what: "Taklister, hall, bibliotek", done: true},
    {days: 4, what: "Stomme kök", done: true},
    {days: 1, what: "Stomme kök", done: true},
    {days: 1, what: "Taklist + golvlist kök", done: true},
    {days: 3, what: "Stomme groventré", done: true},
    {days: 1, what: "Fönsterbräda, golvlist groventré", done: true},
    {days: 1, what: "Golv vardagsrum, taklist, smyg, list, foder", done: true},
    {days: 1, what: "Golv vardagsrum, taklist, smyg, list, foder", done: true},
    {days: 1, what: "Våtskikt WC/D", done: true},
    {days: 1, what: "Kakel WC/D", done: true},
    {days: 1, what: "Klinkers WC/D", done: true},
    {days: 1, what: "Golv, list, foder, hall", done: true},
    {days: 1, what: "Golv, list, foder, hall", done: true},
    {days: 2, what: "Golv, pardörr, foder, smyg bibliotek", done: true},
    {days: 1, what: "Dörr, foder, duschväggar, komod WC/D", done: true},
    {days: 2, what: "Fönsterbrädor, smyg, lister veranda", done: true},
    {days: 1, what: "Golv, foder veranda", done: true},
    {days: 1, what: "Golv, dörr under trapp + dörr skafferi + dörr entré", done: true},
    {days: 1, what: "Sovrum spackel, grundmålning, måla tak", done: true},
    {days: 1, what: "Sovrum spackel, grundmålning, måla tak", done: true},
    {days: 1, what: "Sovrum tapet", done: true},
    {days: 1, what: "Sovrum golv", done: true},
    {days: 2, what: "Sovrum lister, smygar, foder"},
    {days: 2, what: "Hall över gips tak", done: true},
    {days: 1, what: "Hall över gips väggar", done: true},
    {days: 1, what: "Hall över spackel", done: true},
    {days: 1, what: "Hall över spackel", done: true},
    {days: 1, what: "Hall över grundmålning, måla tak", done: true},
    {days: 1, what: "Hall över golv", done: true},
    {days: 1, what: "Hall över, smygar, foder", done: true},
    {days: 1, what: "Hall över, smygar, foder"},
    {days: 1, what: "Föråd över gips tak, väggar", done: true},
    {days: 1, what: "Föråd över spackel", done: true},
    {days: 1, what: "Föråd över grundmålning, måla tak, golv", done: true},
    {days: 1, what: "Föråd över, smygar, foder", done: true},
    {days: 2, what: "Dörrar över, foder", done: true},
    {days: 4, what: "El + VVS fas 2", done: true},
    {days: 2, what: "Färdigställa kök", done: true},
    {days: 1, what: "Färdigställa tvättstuga"}
];

const competitions = [{month: 3, day: 28, competition: "Häxjakten, natt", done: true}];

const WEEK_DAY_SUNDAY = 1;
const WEEK_DAY_MONDAY = 2;
const WEEK_DAY_TUESDAY = 3;
const WEEK_DAY_WEDNESDAY = 4;
const WEEK_DAY_THURSDAY = 5;
const WEEK_DAY_FRIDAY = 6;
const WEEK_DAY_SATURDAY = 0;

const resolveWeekday = weekDay => {
    switch (weekDay) {
        case WEEK_DAY_SUNDAY:
            return "S";
        case WEEK_DAY_MONDAY:
            return "M";
        case WEEK_DAY_TUESDAY:
            return "T";
        case WEEK_DAY_WEDNESDAY:
            return "O";
        case WEEK_DAY_THURSDAY:
            return "T";
        case WEEK_DAY_FRIDAY:
            return "F";
        case WEEK_DAY_SATURDAY:
            return "L";
    }
};

type DayType = {
    day: number;
    weekDayNumber: number;
    weekDay: string;
    delivery?: {what: string; done?: boolean};
    deadline?: {what: string; done?: boolean};
    vacation: boolean;
    workDay: boolean;
    hanna: boolean;
    offDay?: string;
    offDayHalf?: boolean;
    holiday?: {what: string; done?: boolean};
    buffer: boolean;
    week?: number;
    moment?: {what: string; done?: boolean};
    competition?: {competition: string; done?: boolean};
};

const createCalendar = () => {
    const calendar: Array<Array<DayType>> = [];

    for (const src of months) {
        const month: Array<DayType> = [];

        for (const day of Array.from({length: src.days}, (_, i) => i + 1)) {
            const weekDayNumber = (src.weekDayStart + day) % 7;

            month[day] = {
                day,
                weekDayNumber,
                weekDay: resolveWeekday(weekDayNumber),
                delivery: deliveries.find(delivery => delivery.month === src.month && delivery.day === day),
                deadline: deadline.find(deadline => deadline.month === src.month && deadline.day === day),
                vacation: !!vacations.find(vacation => vacation.month === src.month && vacation.day === day),
                workDay: !!workDays.find(workDay => workDay.month === src.month && workDay.day === day),
                hanna: !!hanna.find(hannaDay => hannaDay.month === src.month && hannaDay.day === day),
                offDay: offDays.find(offDay => offDay.month === src.month && offDay.day === day)?.what,
                offDayHalf: offDays.find(offDay => offDay.month === src.month && offDay.day === day)?.half,
                holiday: holidays.find(holiday => holiday.month === src.month && holiday.day === day),
                buffer: !!buffers.find(buffer => buffer.month === src.month && buffer.day === day),
                week: weeks.find(week => week.month === src.month && week.day === day)?.week,
                competition: competitions.find(
                    competition => competition.month === src.month && competition.day === day
                )
            };

            if (
                !(month[day].workDay || month[day].buffer || (month[day].offDay && !month[day].offDayHalf)) &&
                !reservedDays.find(reserved => reserved.month === src.month && reserved.day === day) &&
                moments.length
            ) {
                const moment = moments.shift();

                month[day].moment = moment;

                if (moment.days > 1) {
                    moments.unshift({...moment, days: moment.days - 1});
                }
            }
        }

        calendar[src.month] = month;
    }

    return calendar;
};

const resolveDayClass = (weekDayNumber, workDay, offDay, holiday, buffer) => {
    if (offDay) {
        return " month-day__off";
    } else if (buffer) {
        return " month-day__buffer";
    } else if (weekDayNumber === WEEK_DAY_SUNDAY || holiday) {
        return " month-day__sunday";
    } else if (workDay) {
        return " month-day__work";
    } else {
        return "";
    }
};

const resolveStatusClass = (delivery, vacation, offDay) => {
    if (delivery && vacation) {
        return " month-day-description-status__delivery-vacation";
    } else if (delivery) {
        return " month-day-description-status__delivery";
    } else if (vacation) {
        return " month-day-description-status__vacation";
    } else if (offDay) {
        return " month-day-description-status__off";
    } else {
        return "";
    }
};

const MonthDay = ({
    weekDayNumber,
    weekDay,
    day,
    month,
    delivery,
    deadline,
    vacation,
    workDay,
    hanna,
    offDay,
    offDayHalf,
    holiday,
    buffer,
    week,
    moment,
    competition
}) => {
    const today = new Date();

    return (
        <div
            className={
                "month-day" +
                resolveDayClass(weekDayNumber, workDay, !offDayHalf ? offDay : undefined, holiday, buffer) +
                (today.getDate() === day && today.getMonth() + 1 === month ? " month-day__today" : "")
            }
        >
            <div
                className={
                    "month-day-number" +
                    (weekDayNumber === WEEK_DAY_SATURDAY || weekDayNumber === WEEK_DAY_SUNDAY
                        ? " month-day-number__saturday"
                        : "")
                }
            >
                {weekDay} {day}
            </div>

            <div className="month-day-description">
                <div className="month-day-description-text">
                    {delivery && (
                        <span className={"month-day-description-text__delivery" + (delivery.done ? " is-done" : "")}>
                            Leverans {delivery.what}
                        </span>
                    )}
                    {deadline && (
                        <span className={"month-day-description-text__deadline" + (deadline.done ? " is-done" : "")}>
                            Deadline {deadline.what}
                        </span>
                    )}
                    {holiday && (
                        <span
                            className={
                                (today.getDate() > day && today.getMonth() + 1 === month) ||
                                today.getMonth() + 1 > month
                                    ? " is-done"
                                    : ""
                            }
                        >
                            {holiday.what}
                        </span>
                    )}
                    {offDay && (
                        <span
                            className={
                                (today.getDate() > day && today.getMonth() + 1 === month) ||
                                today.getMonth() + 1 > month
                                    ? " is-done"
                                    : ""
                            }
                        >
                            {offDay}
                        </span>
                    )}
                    {buffer && (
                        <span
                            className={
                                (today.getDate() > day && today.getMonth() + 1 === month) ||
                                today.getMonth() + 1 > month
                                    ? " is-done"
                                    : ""
                            }
                        >
                            Buffer
                        </span>
                    )}
                    {moment && <span className={moment.done ? " is-done" : ""}>{moment.what}</span>}
                    {competition && (
                        <span className={competition.done ? " is-done" : ""}>{competition.competition}</span>
                    )}
                </div>
                <div className={"month-day-description-status" + resolveStatusClass(delivery, vacation, offDayHalf)}>
                    <span>{week}</span>
                    {hanna && <span className="month-day-description-status-hanna"></span>}
                    {competition && <span className="month-day-description-status-competition"></span>}
                </div>
            </div>
        </div>
    );
};

const Month = ({days, monthNumber}) => {
    const {name, leoWork, hannaWork} = months.find(month => month.month === monthNumber);

    return (
        <div className="month">
            <div className="month-header">
                <div className="month-name">{name}</div>
                <div>
                    L {leoWork}% H {hannaWork}%
                </div>
            </div>

            <div className="month-days">
                {days.map((day, index) => (
                    <MonthDay key={index} month={monthNumber} {...day} />
                ))}
            </div>
        </div>
    );
};

export const Calendar = () => {
    const calendar = useMemo(createCalendar, []);

    return (
        <div className="calendar">
            {calendar.map((month, index) => !!month && <Month key={index} days={month} monthNumber={index} />)}
        </div>
    );
};
